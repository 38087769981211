.joinus-box{
    font-size: 1.6rem;
    line-height: 30px;
}

.joinus-box-pic{
    overflow: hidden;
}

.joinus-box-pic img{
    width: 100%;
}